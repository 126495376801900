import React, { useState, useEffect, useRef } from 'react'
import { useFetch, usePost, usePut } from '../../components/component-items/helpers'
import { globalState } from '../../store'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import { TableCell } from '../../components/component-items/datatable'
import { TableRow } from '../company/styles'
import { Form } from 'react-bootstrap'
import { Pages } from '../../components/component-items/pager'
import Checkbox from '../../components/component-items/checkbox'
import { InputGroup } from 'react-bootstrap'
import { SearchBox } from '../../components/component-items/search'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons'

import { EditUserProps } from './types'

export const EditUser = ({ currentUser, open, setOpen }: EditUserProps) => {
  const defaultUser = { first_name: '', last_name: '', email: '', username: '', is_manager: false, is_active: false }
  const [validated, setValidated] = useState(false)
  const [user, setUser] = useState<any>(defaultUser)
  const node = useRef<HTMLFormElement>(null)
  const {
    state: { csrf, warehouseActive },
  } = globalState()

  useEffect(() => {
    if (Object.keys(currentUser).length === 0 && currentUser.constructor === Object) {
      setUser(defaultUser)
    } else {
      setUser(currentUser)
    }
  }, [currentUser])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    if (user.id) {
      usePut(`/api/warehouse/${warehouseActive.id}/users/${user.id}/`, user, csrf)
    } else {
      usePost(`/api/warehouse/${warehouseActive.id}/users/`, user, csrf)
    }
  }

  return (
    <GenericModal
      heading={user.id ? 'Update User' : 'Create User'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{user.id ? 'Update' : 'Create'}</GlobalStyles.Button>}
    >
      <Form ref={node} noValidate validated={validated} style={{ margin: '2em' }}>
        <Form.Group className="required">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            value={user.first_name}
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Please enter a valid first name.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="required">
          <Form.Label style={{ marginTop: '1em' }}>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            value={user.last_name}
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Please enter a valid last name.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="required">
          <Form.Label style={{ marginTop: '1em' }}>Email</Form.Label>
          <Form.Control
            required
            type="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="required">
          <Form.Label style={{ marginTop: '1em' }}>Username</Form.Label>
          <Form.Control
            required
            type="text"
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Please enter a valid username.</Form.Control.Feedback>
        </Form.Group>
        <InputGroup style={{ marginTop: '1.5em' }}>
          <Checkbox selected={user.is_manager} setSelected={() => setUser({ ...user, is_manager: !user.is_manager })} />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Manager</p>
        </InputGroup>
        <InputGroup style={{ marginTop: '1em' }}>
          <Checkbox selected={user.is_active} setSelected={() => setUser({ ...user, is_active: !user.is_active })} />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Active</p>
        </InputGroup>
      </Form>
    </GenericModal>
  )
}

export const WarehouseUsersPage = () => {
  const {
    state: { csrf, warehouseActive },
  } = globalState()

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState({})
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(`/api/warehouse/${warehouseActive.id}/users/?page=${page}&q=${encodeURIComponent(search)}`, {})
  const { count: total, results: results }: any = loaded ? resp : {}

  useEffect(() => {
    if (loaded && results && totalPages === 1) {
      setTotalPages(Math.ceil(total / results.length))
    }
    setLoading(!loaded)
  }, [loaded])

  useEffect(() => {
    setUsers(results)
  }, [results])

  const updateUser = (user_id: string | number, action: any) => () => {
    const newState: any = users.map((user: any) => {
      if (user.id === user_id) {
        if (action === 'manager') {
          return { ...user, is_manager: !user.is_manager }
        }
        return { ...user, is_active: !user.is_active }
      }
      return user
    })
    setUsers(newState)
    usePut(`/api/warehouse/${warehouseActive.id}/users/${user_id}/toggle_${action}/`, {}, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Warehouse Users<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus updateURL />
          <GlobalStyles.Button
            onClick={() => {
              setUser({})
              setOpen(true)
            }}
          >
            Add User
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Name</span>
              </th>
              <th>
                <span className="text">Email</span>
              </th>
              <th>
                <span className="text">Username</span>
              </th>
              <th>
                <span className="text center">Manager</span>
              </th>
              <th>
                <span className="text center">Active</span>
              </th>
              <th>
                <span className="text center">Last Login</span>
              </th>
              <th>
                <span className="text center">Date Joined</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((u: any, id: number) => (
              <TableRow key={id}>
                <TableCell input={u.first_name + ' ' + u.last_name} />
                <TableCell input={u.email} />
                <TableCell input={u.username} />
                <TableCell
                  center
                  input={
                    <Form.Check
                      type="switch"
                      id={'manager-' + u.id}
                      defaultChecked={u.is_manager}
                      onChange={updateUser(u.id, 'manager')}
                    />
                  }
                />
                <TableCell
                  center
                  input={
                    <Form.Check
                      type="switch"
                      id={'active-' + u.id}
                      defaultChecked={u.is_active}
                      onChange={updateUser(u.id, 'active')}
                    />
                  }
                />

                <TableCell center input={u.last_login ? new Date(u.last_login).toLocaleDateString('en-US') : null} />
                <TableCell center input={new Date(u.date_joined).toLocaleDateString('en-US')} />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      <button
                        onClick={() => {
                          setUser(u)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </div>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
        <>
          <br />
          <Pages page={page} setPage={(page: number) => setPage(page)} total={totalPages} />
          <br />
        </>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      <EditUser {...{ open, setOpen }} currentUser={user} />
    </GlobalStyles.FullPageCard>
  )
}
