import React, { useEffect, useState } from 'react'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import { Form, InputGroup } from 'react-bootstrap'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Pages } from '../../../components/component-items/pager'
import Checkbox from '../../../components/component-items/checkbox'
import { DragNDrop } from '../../../components/component-items/dragndrop'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { StatusBarStyled } from './styles'
import CardLoading from '../../../components/component-items/loading-popover'
import { TableCell } from '../../../components/component-items/datatable'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'

// Fontawesome
import styled from 'styled-components'

export const PostageReconcile = () => {
  const [file, setFile] = useState<any>(null)
  const [response, setResponse] = useState<any>({ success: false, error: false })
  const {
    state: { csrf },
  } = globalState()

  const [type, setType] = useState('')
  const [preview, setPreview] = useState<any>(false)

  const uploadFile = async () => {
    if (!file) {
      return
    }

    var formData = new window.FormData()
    formData.append('reconcile', file, file.name)
    formData.append('preview', preview)
    formData.append('type', type)

    const reload = false
    const stringifyBody = false
    const successMessage =
      'Success: the file has been uploaded and you will receive an email once we have processed all transactions'
    const errorMessage = 'Something went wrong, please confirm the template being used is correct'
    const resp = await usePost('/api/postage/postage-reconcile/', formData, csrf, reload, stringifyBody)
    setResponse(resp.success ? { success: successMessage, error: false } : { success: false, error: errorMessage })
    // @ts-ignore
    setFile()
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <StatusBarStyled>
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#ffb822' }} />
              </div>
              <div className="status-text">
                <strong>Please Note</strong>: CSV Files must be formatted like the template, any errors will be reported
                below, and the upload will fail.
              </div>
            </div>
          </StatusBarStyled>
        </div>
      </div>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Reconcile Postage Adjustments</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '50%' }}>
          <StatusBoxStyled className={'purple'} style={{ marginBottom: '2em' }}>
            Note: CSV files are required, if the format is in XLSX (excel), please save as ".csv" and upload. Also note,
            uploads can take 30 minutes or more depending on the number of transcations present.
          </StatusBoxStyled>
          {response.success ? (
            <StatusBoxStyled className={'green'} style={{ marginBottom: '2em' }}>
              {response.success}
            </StatusBoxStyled>
          ) : response.error ? (
            <StatusBoxStyled className={'red'} style={{ marginBottom: '2em' }}>
              {response.error}
            </StatusBoxStyled>
          ) : null}
          <InputGroup>
            <Checkbox selected={preview} setSelected={() => setPreview(!preview)} />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>
              Preview (If selected - no billing transactions are added and results are emailed to you)
            </p>
          </InputGroup>
          <Form.Label>Carrier</Form.Label>
          <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="" hidden disabled>
              Select
            </option>
            <option value="fedex">FedEx</option>
            <option value="ups">UPS</option>
            <option value="usps">USPS</option>
            <option value="ontrac">OnTrac</option>
            <option value="passport">Passport</option>
            <option value="dhl_ecomm">DHL eCommerce</option>
          </Form.Select>
          <Form.Label style={{ marginTop: '1em' }}>CSV File Upload</Form.Label>
          <DragNDrop {...{ file, setFile }} fileTypes={'.csv'} size={20} />
          <GlobalStyles.Button onClick={() => uploadFile()} style={{ minWidth: 150, margin: 0, float: 'right' }}>
            Submit
          </GlobalStyles.Button>
        </div>
      </GlobalStyles.FullPageCard>
      <AdjustmentList />
    </>
  )
}

export const TableRow = styled(GlobalStyles.TableRow)`
  p {
    max-width: fit-content;
    margin-bottom: 0;
  }
`

export const AdjustmentList = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const {
    response: resp,
    loaded,
    error,
    placeholder,
  }: any = useFetch(`/api/postage/adjustment_list/?page=${currentPage}`, {})
  const { count: total, results: documents }: any = loaded ? resp : {}

  useEffect(() => {
    if (loaded && totalPages === 1) {
      setTotalPages(Math.ceil(total / documents.length))
    }
  }, [loaded])

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>Postage Reconciling Uploads</h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Created</span>
              </th>
              <th>
                <span className="text">Title</span>
              </th>
              <th>
                <span className="text center">Adjusted</span>
              </th>
              <th>
                <span className="text center">Type</span>
              </th>
              <th>
                <span className="text center">Download</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {documents?.map(({ id, title, url, adjusted, source, created }: any) => (
              <TableRow key={id}>
                <TableCell>
                  {new Date(created).toLocaleDateString('en-US', {
                    month: 'short',
                    year: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })}
                </TableCell>
                <TableCell input={title} />
                <TableCell center>
                  {adjusted ? <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'purple' }} /> : null}
                </TableCell>
                <TableCell center>
                  <StatusBoxStyled className="blue" style={{ width: 100, margin: 'auto' }}>
                    {source}
                  </StatusBoxStyled>
                </TableCell>
                <TableCell center>
                  {url ? (
                    <a href={url}>
                      <GlobalStyles.Button style={{ color: 'white' }}>Download</GlobalStyles.Button>
                    </a>
                  ) : (
                    <StatusBoxStyled className="purple" style={{ width: 100, margin: 'auto' }}>
                      Downloading
                    </StatusBoxStyled>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
        {totalPages > 1 && (
          <>
            <br />
            <Pages page={currentPage} setPage={(page: number) => setCurrentPage(page)} total={totalPages} />
            <br />
          </>
        )}
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
