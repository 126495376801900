import React, { useEffect, useRef, useState } from 'react'
import { notify, usePost } from '../../components/component-items/helpers'
import { globalState } from '../../store'

import { Form, Col, Row } from 'react-bootstrap'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { StatusBoxStyled } from '../../components/component-items/status-box'

export const ExitScanPage = () => {
  const [tracking, setTracking] = useState('')
  const [sscc, setSSCC] = useState('')
  const [carrierName, setCarrierName] = useState('')

  const {
    state: { csrf },
  } = globalState()

  const trackingInput = useRef<HTMLInputElement>(null)
  const containerInput = useRef<HTMLInputElement>(null)
  const focusTrackingInput = () => {
    if (trackingInput.current) {
      trackingInput.current.focus()
      trackingInput.current.select()
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    let resp = await usePost('/api/shipment/exit_scan/', { ...{ tracking, sscc } }, csrf, false, true, true)
    if (resp.error) {
      confirm(resp.error)
    } else {
      notify({ type: 'success', message: 'Exit Scan Successful' })
      setTracking('')
      setSSCC('')
      setCarrierName('')
      focusTrackingInput()
    }
  }

  useEffect(() => {
    focusTrackingInput()
  }, [])

  const handleKeyDown = async (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      // Retrieve label preview
      await usePost('/api/shipment/retrieve_carrier/', { ...{ tracking } }, csrf, false).then((resp) => {
        if (resp.carrier) {
          setCarrierName(resp.carrier.name)
          containerInput.current?.focus()
        } else {
          setCarrierName('')
        }
      })
    }
  }

  return (
    <>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Exit Scan</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ margin: '2em auto', width: '50%' }}>
          <Form onSubmit={handleSubmit}>
            <Row style={{ justifyContent: 'center' }}>
              <Form.Group as={Col} md="12">
                <Form.Label>Tracking</Form.Label>
                <Form.Control
                  ref={trackingInput}
                  value={tracking}
                  type="text"
                  onChange={(e) => setTracking(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>
              <Form.Group as={Col} md="12" style={{ textAlign: 'center', width: 250, margin: '5px 0' }}>
                {carrierName && (
                  <StatusBoxStyled className={'purple'} style={{ fontSize: '1.4em' }}>
                    {carrierName}
                  </StatusBoxStyled>
                )}
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>Container ID</Form.Label>
                <Form.Control ref={containerInput} value={sscc} type="text" onChange={(e) => setSSCC(e.target.value)} />
              </Form.Group>
            </Row>
            <GlobalStyles.Button type="submit" style={{ minWidth: 150, margin: 0, float: 'right' }}>
              Submit
            </GlobalStyles.Button>
          </Form>
        </div>
      </GlobalStyles.FullPageCard>
    </>
  )
}
