import React, { useEffect, useState, useRef } from 'react'
import { toast, Zoom } from 'react-toastify'
import { usePost, usePut, useDelete } from '../../../components/component-items/helpers'
import { ConfirmToast } from '../../../components/component-items/ConfirmToast'
import { globalState } from '../../../store'
import GenericModal from '../../../components/component-items/modal'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Row, Col, Form, InputGroup } from 'react-bootstrap'

const enum WebhookStatus {
  Disabled = 0,
  Enabled = 1,
  Locked = 2,
}

type Props = {
  onUpdate: () => void
  hide: () => void
  webhook?: any
}
export const WebhookFormPage = ({ hide, onUpdate, webhook }: Props) => {
  const {
    state: { csrf },
  } = globalState()
  const toastId: any = useRef(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [data, setData] = useState<any>({
    event_type: 'order',
    status: WebhookStatus.Enabled,
    ...webhook,
  })

  useEffect(() => {
    if (webhook?.id) {
      setIsDisabled(webhook.has_events)
    }
  }, [webhook])

  const eventTypes = [
    { key: 'order', label: 'Order' },
    { key: 'product_variant', label: 'Product Variant' },
    { key: 'ship_notice', label: 'Ship Notice' },
    { key: 'return_notice', label: 'Return Notice' },
  ]

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (!webhook?.id) {
      const response: any = await usePost(`/api/core/webhooks/`, data, csrf, false, true, true, true, false)
      if (response?.id) {
        onUpdate()
      } else {
        toast('Failed to create Webhook', { type: 'error' })
      }
    } else {
      const response: any = await usePut(
        `/api/core/webhooks/${webhook.id}/`,
        data,
        csrf,
        false,
        true,
        true,
        true,
        false
      )
      if (response?.id) {
        onUpdate()
      } else {
        toast('Failed to update Webhook', { type: 'error' })
      }
    }
  }

  const handleTest = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const { status } = await usePost(
      `/api/core/webhooks/${webhook?.id}/test/`,
      {},
      csrf,
      false,
      true,
      true,
      true,
      false
    )
    if (status >= 200 && status < 300) {
      toastId.current = toast('Successfully sent a simple JSON POST to your endpoint', { type: 'info' })
    } else {
      toastId.current = toast(
        'Failed to send a simple JSON POST to your endpoint. Try returning a 200 status response',
        { type: 'error' }
      )
    }
  }

  const onConfirmDelete = async () => {
    const res = await useDelete(`/api/core/webhooks/${webhook?.id}/`, csrf, false, true, false)
    if (res === undefined) {
      toast.update(toastId.current, {
        render: 'Webhook endpoint deleted',
        type: 'info',
        transition: Zoom,
      })
      onUpdate()
      hide()
    }
  }

  const onClickDelete = async () => {
    toastId.current = toast(
      <ConfirmToast
        body="Are you sure that you want to delete this endpoint?"
        title="Warning!"
        onSubmit={onConfirmDelete}
        requestConfirmation
      />,
      {
        type: 'warning',
      }
    )
  }

  const setInputValue = (key: string, value: any) => {
    setData({ ...data, [key]: value })
  }

  return (
    <GenericModal
      heading={'Webhook Endpoint'}
      show={true}
      size="lg"
      buttons={
        <>
          {webhook?.id && (
            <>
              <GlobalStyles.Button className="red" type="button" style={{ width: '150px' }} onClick={onClickDelete}>
                Delete
              </GlobalStyles.Button>
              <GlobalStyles.Button className="secondary" style={{ width: '150px' }} onClick={handleTest}>
                Test
              </GlobalStyles.Button>
            </>
          )}
          <GlobalStyles.Button className="primary" style={{ width: '150px' }} onClick={handleSubmit}>
            Save
          </GlobalStyles.Button>
        </>
      }
      onHide={hide}
    >
      <GlobalStyles.FullPageCard style={{ minHeight: 300, padding: '2em' }}>
        <Form>
          <Row>
            <Col md="6">
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Order Webhook"
                    defaultValue={webhook?.name}
                    onChange={(e) => setInputValue('name', e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please choose a name.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Webhook to notify of order events"
                    defaultValue={webhook?.description}
                    onChange={(e) => setInputValue('description', e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">Please add a valid description.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group controlId="url">
                <Form.Label>Endpoint url</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="https://app.shippingtree.co/webhooks"
                    defaultValue={webhook?.url}
                    disabled={isDisabled}
                    onChange={(e) => setInputValue('url', e.target.value)}
                    isInvalid={webhook?.url?.length > 0 && !webhook?.url?.startsWith('http')}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please choose a url.</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group controlId="eventType">
                <Form.Label>Event type</Form.Label>
                <Form.Select
                  disabled={isDisabled}
                  value={webhook?.event_type}
                  onChange={(e) => setInputValue('event_type', e.target.value)}
                >
                  {eventTypes.map((eventType) => (
                    <option value={eventType.key} key={eventType.key}>
                      {eventType.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">Please add a valid Event type.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="status">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  value={data?.status}
                  onChange={(e) => setInputValue('status', e.target.value)}
                  disabled={webhook?.status === WebhookStatus.Locked}
                >
                  <option value={WebhookStatus.Enabled}>Enabled</option>
                  <option value={WebhookStatus.Disabled}>Disabled</option>
                  {webhook?.status === WebhookStatus.Locked && <option value={WebhookStatus.Locked}>Locked</option>}
                </Form.Select>
                <Form.Control.Feedback type="invalid">Please add a valid option.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </GlobalStyles.FullPageCard>
    </GenericModal>
  )
}
