import ReactMarkdown from 'react-markdown'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import rehypeSanitize from 'rehype-sanitize'
import { format } from 'date-fns'

import { useFetch } from '../../../components/component-items/helpers'
import { NotificationIcon } from '../../../components/notifications/utils'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import CardLoading from '../../../components/component-items/loading-popover'
import { OrderInfo, Line, H4 } from '../styles'

// Types
import { ChangesProps } from '../types'

// Modals
import { EntryModal } from './new-entry'

export const Changelog = () => {
  const {
    state: { userIsExecutive },
  } = globalState()
  const location = useLocation()
  const [changes, setChanges] = useState<ChangesProps[]>([])
  const [entryModal, setEntryModal] = useState(false)

  const { response, loaded, error, placeholder } = useFetch('/api/core/changelog/', {})

  useEffect(() => {
    if (loaded) {
      setChanges((response as any).results || [])
    }
    const hash = location.hash.replace('#', '')
    const activeSection = hash.split('=')[1]
    // scroll to active section
    if (activeSection) {
      const key = normalizeKey(activeSection)
      setTimeout(() => {
        const el = document.getElementById(key)
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 1000)
    }
  }, [loaded, location.hash])

  const normalizeKey = (key: string) => {
    return key
      ? key
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, '')
          .replace(/ /g, '-')
          .replace(/^-+/, '')
      : key
  }

  return (
    <OrderInfo style={{ display: 'block', height: 'unset' }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>Changelog</h3>
        </GlobalStyles.CardTitle>
        {userIsExecutive && (
          <GlobalStyles.CardToolbar>
            <GlobalStyles.Button onClick={() => setEntryModal(true)}>Add Entry</GlobalStyles.Button>
            <EntryModal {...{ entryModal, setEntryModal }} />
          </GlobalStyles.CardToolbar>
        )}
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, width: '100%', maxHeight: '60vh', overflowY: 'scroll' }}>
        <GlobalStyles.DataTable>
          <tbody>
            {changes.map(({ id, title, description, created, type }) => (
              <GlobalStyles.TableRow key={id} id={normalizeKey(title) || String(id)}>
                <GlobalStyles.TableCell style={{ verticalAlign: 'top', position: 'relative' }}>
                  <div style={{ textAlign: 'center', minWidth: '40px', fontSize: '2.5em', marginTop: '0.9em' }}>
                    {NotificationIcon({ type })}
                    <Line />
                  </div>
                </GlobalStyles.TableCell>
                <TableCell>
                  <h1 style={{ textTransform: 'capitalize', marginTop: '1em' }}>
                    {type.replace('_', ' ')} - {title}
                  </h1>
                  <div style={{ color: 'black' }}>{format(new Date(created), 'MMMM d, yyyy')}</div>
                  <ReactMarkdown
                    // @ts-ignore
                    rehypePlugins={[rehypeSanitize]}
                    components={{
                      h1: H4,
                      h2: H4,
                      h3: H4,
                      h4: H4,
                      h5: H4,
                      h6: H4,
                    }}
                  >
                    {description}
                  </ReactMarkdown>
                </TableCell>
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </OrderInfo>
  )
}
