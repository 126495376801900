import React, { useContext, useState } from 'react'
import { useDelete, useFetch } from '../../../components/component-items/helpers'
import { BackgroundLayer } from '../styles'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell, THSort } from '../../../components/component-items/datatable'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faTimes, faEdit, faExternalLink, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import { formatDate } from '../../../components/component-items/utils'
import { store } from '../../../store'
import { LargeButton } from '../../../components/component-items/buttons'

// Modals
import { EDIRetailerModal } from './retailer-modal'
import CardLoading from '../../../components/component-items/loading-popover'

const Tooltip = styled(GlobalStyles.Tooltip)`
  &:before {
    width: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

export const EDIRetailers = () => {
  const {
    state: { userIsWarehouse, actAs, csrf },
  } = useContext(store)

  const [sort, setSort] = useState({})
  const [open, setOpen] = useState(false)
  const [retailerEdit, setRetailerEdit] = useState({})

  const url = '/api/edi/retailer/'
  const { response: resp, loaded, placeholder, error } = useFetch(url, {})
  console.log(resp)
  const retailers = resp?.results || []
  const total = resp?.count || 0

  const handleDelete = (id: number) => {
    useDelete(`/api/edi/retailer/${id}/`, csrf)
  }

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            EDI Retailers<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 200, width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Retailer</span>
              </th>
              <th>
                <span className="text center">Created</span>
              </th>
              {userIsWarehouse && !actAs && (
                <th>
                  <span className="text">Merchant</span>
                </th>
              )}
              <th>
                <span className="text">Reference ID</span>
              </th>
              <th>
                <span className="text center">GS1 Labels</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {retailers.map(({ id, company, created, retailer, referenceId, usesGs1Labels }: any) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell input={retailer} />
                <TableCell>{formatDate(created)}</TableCell>
                {userIsWarehouse && !actAs && <TableCell>{company}</TableCell>}
                <TableCell>{referenceId}</TableCell>
                <TableCell center>
                  {usesGs1Labels ? (
                    <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#51e2d3' }} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                </TableCell>
                <TableCell
                  input={
                    <div className="dropdown__container" style={{ display: 'flex', justifyContent: 'center' }}>
                      <>
                        {/* TODO - Add the below in a future update */}
                        {/* <Tooltip className="subheader__button button" data-title="View Routing Guidelines">
                          <LargeButton>
                            <FontAwesomeIcon icon={faExternalLink} />
                          </LargeButton>
                        </Tooltip> */}
                        <Tooltip className="subheader__button button" data-title="Edit Retailer">
                          <LargeButton
                            onClick={() => {
                              setRetailerEdit(retailers.find((r: any) => r.id === id))
                              setOpen(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} color="blue" />
                          </LargeButton>
                        </Tooltip>
                        <EDIRetailerModal {...{ open, setOpen }} retailer={retailerEdit} />
                        <Tooltip className="subheader__button button" data-title="Delete Retailer">
                          <LargeButton onClick={() => handleDelete(id)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </LargeButton>
                        </Tooltip>
                      </>
                    </div>
                  }
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </BackgroundLayer>
  )
}
