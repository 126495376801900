import React, { useState, useContext } from 'react'
import { store } from '../../store'
import { StickyElement } from '../../components/component-items/helpers'
import { MenuOptions } from './styles'

// Components
import GlobalStyles from '../../components/component-items/styles'

// Cards
import { StatusBar } from './status-bar'
import { EDITransactions } from './transactions'
import { EDISettings } from './settings'

// Modals
import { EDIRetailerModal } from './retailers/retailer-modal'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faExternalLink, faPlusHexagon } from '@fortawesome/pro-duotone-svg-icons'

import { MenuOptionProps } from './types'
import { EDIRetailers } from './retailers'
import { EDISummary } from './summary'

const MenuOption = ({ url, onClick, icon, title, arrow, children, node }: MenuOptionProps) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const EDITransactionList = () => {
  const {
    state: { userIsWarehouse, actAs },
  } = useContext(store)

  const [open, setOpen] = useState(false)

  return (
    <>
      <StatusBar />
      <div className="row">
        <div className="col-xl-2">
          <StickyElement>
            <MenuOptions>
              <ul>
                {(!userIsWarehouse || actAs) && (
                  <GlobalStyles.Tooltip data-title="Add additional retailers">
                    <MenuOption onClick={() => setOpen(true)} icon={faPlusHexagon} title={'Add Retailer'} />
                  </GlobalStyles.Tooltip>
                )}
                <EDIRetailerModal {...{ open, setOpen }} />
                {userIsWarehouse && (
                  <GlobalStyles.Tooltip data-title="Add a new trade request using Orderful">
                    <MenuOption
                      onClick={() => window.open('https://ui.orderful.com/trade-requests')}
                      icon={faExternalLink}
                      title={'Orderful Trade Request'}
                    />
                  </GlobalStyles.Tooltip>
                )}
              </ul>
            </MenuOptions>
          </StickyElement>
        </div>
        <div className="col-xl-7">
          <div className="row">
            <div className="col-12">
              <EDIRetailers />
              <EDITransactions />
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <StickyElement>{userIsWarehouse && !actAs ? <EDISummary /> : <EDISettings />}</StickyElement>
        </div>
      </div>
    </>
  )
}
