import * as React from 'react'
import { useContext, useEffect, useLayoutEffect, useState, useRef } from 'react'
import { BrowserRouter as Router, useLocation, useHistory, Route, Redirect, Switch } from 'react-router-dom'
import { store, StateProvider } from './store'
import { ShippingTreeTheme } from './theme/ShippingTreeTheme'
import ShippingTreeToastContainer from './containers/ShippingTreeToastContainer'

import 'react-medium-image-zoom/dist/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import { PostPage } from './pages/login/styles'

// Components
import { notify } from './components/component-items/helpers'
import { Chrome, Background, Border } from './components/chrome/chrome'
import CrumbRoute from './containers/CrumbRoute'

// Global
import LoginPage from './pages/login'
import { NotFoundPage } from './pages/error'
import { BadRequestPage } from './pages/error/bad-request'
import { WarehouseDashboard, MerchantDashboard } from './pages/dashboard'

// Orders
import { OrderList } from './pages/orders'
import { CreateOrder } from './pages/orders/new'
import { UploadOrders } from './pages/orders/upload'
import { OrderPage } from './pages/orders/order'

//ASNs
import { AsnList } from './pages/asns'
import { CreateAsn } from './pages/asns/new'
import { AsnPage } from './pages/asns/asn'
import { SelectItem } from './pages/asns/asn/item'
import { ConfirmDetails } from './pages/asns/asn/item/confirm-details'
import { ReceiveInventory } from './pages/asns/asn/item/receive-inventory'
import { NonCompliance } from './pages/asns/asn/item/non-compliance'
import { ReturnList } from './pages/returns'

// Products
import { ProductList } from './pages/products'
import { UploadPrices } from './pages/products/upload-prices'
import { CreateProduct } from './pages/products/new'
import { ProductPage } from './pages/products/product'
import { PromoRules } from './pages/products/rules'
import { ProductVariantPage } from './pages/products/product/variant'
import { PackageList } from './pages/packages'
import { CreatePackaging } from './pages/packages/new'
import { PackagingRules } from './pages/packages/rules'

// Postage
import { PostageReconcile } from './pages/postage/reconcile'
import { RateCalculator } from './pages/postage/rate-calculator'
import { PostageManifests } from './pages/postage/manifests'

// Projects
import { Project } from './pages/projects/project'
import { ProjectList } from './pages/projects'

// Locations
import { LocationList } from './pages/locations'
import { LocationEdit } from './pages/locations/edit'
import { LocationView } from './pages/locations/location'

// Company Pages
import { Companies } from './pages/companies'
import { CompanyUsers } from './pages/company/users'
import { CompanySettings } from './pages/company/settings'
import { Profile } from './pages/company/profile'
import { PackingSlip } from './pages/company/packing-slip'
import { ShippingMethods } from './pages/company/shipping-methods'
import { EmailNotificationList } from './pages/company/email-notifications'
import { Reports } from './pages/company/reports'

// Billing Pages
import { BillingPage } from './pages/billing/header'
import { CommissionsReport } from './pages/billing/commissions'
import { PricingTier } from './pages/billing/pricing-tier'
import { PricingTool } from './pages/billing/pricing-tool'

// Inventory Pages
import { InventoryList } from './pages/inventory'
import { StockAlertsPage } from './pages/company/stock-alerts'
import { RunDownRatePage } from './pages/inventory/run-down-rate'
import { MoveInventoryPage } from './pages/inventory/move-inventory'

// Picking Workflow
import { PickingLocation } from './pages/warehouse/picking/location'
import { MatrixSlotting } from './pages/warehouse/picking/matrix-slotting'
import { BulkSlotting } from './pages/warehouse/picking/bulk-slotting'

// Packing Workflow
import { PackOrder, PrePackOrder } from './pages/warehouse/packing/pack-order'
import { PackSingleOrder } from './pages/warehouse/packing/pack-order/pack-single-order'
import { SerialNumbers } from './pages/warehouse/packing/serial-numbers'
import { ConfirmDimensions, PrePackDimensions } from './pages/warehouse/packing/confirm-dimensions'
import { LabelPurchase } from './pages/warehouse/packing/label-purchase'
import { Freight } from './pages/warehouse/packing/freight'

// Warehouse
import { ShipOrderPage } from './pages/ship-order-page'
import { ExitScanPage } from './pages/exit-scan'
import { WarehouseUsersPage } from './pages/warehouse/users'
import { ReceiveASN } from './pages/asns/receive-asn'

// EDI
import { EDITransactionList } from './pages/edi'

// Automations
import { Actions } from './pages/actions/'

// Batches
import { BatchList } from './pages/warehouse/batches'
import { StartBatch } from './pages/warehouse/batches/start'
import { Batch } from './pages/warehouse/batches/batch'
import { BatchProfile } from './pages/warehouse/batches/batch-profile'

// Integrations
import { ShopifyIntegrationPage } from './pages/integrations/shopify'
import { LoopIntegrationPage } from './pages/integrations/loop'
import { QuickbooksIntegrationPage } from './pages/integrations/quickbooks'
import { ListWebhookPage, WebhookEventsPage } from './pages/company/webhooks'

// Core
import { AsyncTaskList } from './pages/core/tasks'

// Keyboard Shortcuts
import { connectShortcuts } from './keyboard-shortcuts'
import { NotificationPage } from './pages/notifications'

const loader = document.querySelector('.loader-container')

const hideLoader = () => loader?.classList.add('loader--hide')

const MainRoutes = () => {
  const location = useLocation()
  const history = useHistory()
  const {
    state: { isAuthenticated, actAs, csrf, userIsWarehouse },
    dispatch,
  } = useContext(store)
  const [isLoading, setLoading] = useState(true)
  const isLoadingRef = useRef(isLoading)
  isLoadingRef.current = isLoading

  let error = new URLSearchParams(location.search).get('error')
  let nextUrl = new URLSearchParams(location.search).get('next')
  let returnTo = new URLSearchParams(location.search).get('returnto')

  var searchParams = new URLSearchParams(location.search)
  searchParams.set('next', location.pathname)

  // Keyboard Shortcuts:
  // Attaches listener on a global level for keyboard shortcuts
  // such as: Start Shipping, Exit Scanning, etc
  useEffect(() => {
    if (userIsWarehouse) {
      const handleKeyDown = (event) => {
        connectShortcuts(event, null, 'global', history)
      }

      document.addEventListener('keydown', handleKeyDown)
      return () => document.removeEventListener('keydown', handleKeyDown)
    }
  }, [userIsWarehouse])

  useEffect(() => {
    getCSRF()
    const timer = setTimeout(() => {
      if (isLoadingRef.current) {
        window.location.reload()
      }
    }, 15000)
    return () => clearTimeout(timer)
  }, [])

  useLayoutEffect(() => {
    getSession()
    // Show toast when error is redirected with URL
    if (error) {
      notify({ type: 'error', message: error })
      var errorSearchParams = new URLSearchParams(location.search)
      errorSearchParams.delete('error')
      history.push({ search: errorSearchParams.toString() })
    }
  }, [])

  const getCSRF = () => {
    fetch('/api/csrf/', {
      credentials: 'include',
    })
      .then((res) => {
        let csrfToken = res.headers.get('X-CSRFToken')
        dispatch({ type: 'csrf', csrf: csrfToken })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSession = async () => {
    fetch('/api/session/', {
      credentials: 'include',
      headers: {
        'X-CSRFToken': csrf,
      },
    })
      .then((res) => res.json())
      .then((payload) => {
        if (payload.isAuthenticated) {
          dispatch({ type: 'session', payload: payload })
        } else {
          throw Error(payload.detail)
        }
        setLoading(false)
        hideLoader()
      })
      .catch((err) => {
        console.log(err)
        getCSRF()
        setLoading(false)
        hideLoader()
      })
  }
  return isLoading ? null : (
    <Switch>
      <Route
        exact
        path="/login/"
        render={(props) =>
          isAuthenticated ? (
            nextUrl ? (
              nextUrl.startsWith('/api') ? (
                window.location.replace(nextUrl)
              ) : (
                <Redirect to={nextUrl} />
              )
            ) : (
              <Redirect to={userIsWarehouse ? '/warehouse/dashboard/' : '/company/dashboard/'} />
            )
          ) : (
            <LoginPage {...props} />
          )
        }
      />
      <Route exact path="/404/" component={NotFoundPage} />
      <Route exact path="/500/" component={BadRequestPage} />
      <Route exact path="/">
        <Redirect to="/login/" />
      </Route>
      {isAuthenticated ? (
        <Chrome>
          <Switch>
            <Route
              path="/core"
              render={({ match: { url } }) => (
                <Switch>
                  <CrumbRoute exact title="Async Tasks" path={`${url}/tasks/`} component={AsyncTaskList} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <Route
              path="/warehouse"
              render={({ match: { url } }) => (
                <Switch>
                  <CrumbRoute
                    exact
                    title="Companies"
                    path={`${url}/companies`}
                    render={(props) =>
                      returnTo && actAs ? window.location.replace(returnTo) : <Companies {...props} />
                    }
                  />
                  <CrumbRoute exact title="Exit Scan" path={`${url}/exit-scan/`} component={ExitScanPage} />
                  <CrumbRoute exact path={`${url}/dashboard/`} component={WarehouseDashboard} />
                  <CrumbRoute exact path={`${url}/users/`} component={WarehouseUsersPage} />
                  <CrumbRoute exact title="Start Shipping" path={`${url}/start-shipping/`} component={ShipOrderPage} />
                  <CrumbRoute exact title="Start Receiving" path={`${url}/start-receiving/`} component={ReceiveASN} />
                  <Redirect from={`${url}/order/:id/`} to="/order/:id/" />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="Orders"
              path="/order"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={OrderList} />
                  <CrumbRoute exact companyRequired title="New" path={`${url}/new/`} component={CreateOrder} />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Upload Orders"
                    path={`${url}/upload-orders/`}
                    component={UploadOrders}
                  />
                  <CrumbRoute
                    strict
                    title="Order Page"
                    path={`${url}/:id(\\d+)/`}
                    render={({ match: { path } }) => (
                      <Switch>
                        <Route exact strict path={`${path}`} render={(props) => <OrderPage {...props} />} />
                        <CrumbRoute exact strict title="Pack Order" path={`${path}pack/`} component={PackOrder} />
                        <CrumbRoute
                          exact
                          strict
                          title="Pre-Pack Order"
                          path={`${path}pre-pack/`}
                          component={PrePackOrder}
                        />
                        <CrumbRoute
                          exact
                          strict
                          title="Add Serial Numbers"
                          path={`${path}serial-numbers/`}
                          component={SerialNumbers}
                        />
                        <CrumbRoute
                          exact
                          strict
                          title="Confirm Dimensions"
                          path={`${path}confirm-dimensions/`}
                          component={ConfirmDimensions}
                        />
                        <CrumbRoute
                          exact
                          strict
                          title="Pre-Pack Dimensions"
                          path={`${path}pre-pack-dimensions/`}
                          component={PrePackDimensions}
                        />
                        <CrumbRoute
                          exact
                          strict
                          title="Label Purchase"
                          path={`${path}label-purchase/`}
                          component={LabelPurchase}
                        />
                        <CrumbRoute exact strict title="Freight" path={`${path}freight/`} component={Freight} />
                        <Route component={NotFoundPage} />
                      </Switch>
                    )}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="Batches"
              path="/batch"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={BatchList} />
                  <CrumbRoute exact title="Batch Profile" path={`${url}/profile/`} component={BatchProfile} />
                  <CrumbRoute
                    strict
                    title={'Batch'}
                    path={`${url}/:id(\\d+)/`}
                    render={({ match: { path } }) => (
                      <Switch>
                        <Route exact path={`${path}`} component={Batch} />
                        <CrumbRoute exact strict title="Start Batch" path={`${path}start/`} component={StartBatch} />
                        <CrumbRoute
                          exact
                          strict
                          title="Pack Single Item Orders"
                          path={`${path}pack-single/`}
                          component={PackSingleOrder}
                        />
                        <CrumbRoute
                          strict
                          title="Pick Location"
                          path={`${path}pick/:inventory_item(\\d+)/`}
                          render={({ match: { path } }) => (
                            <Switch>
                              <Route exact path={`${path}/`} component={PickingLocation} />
                              <CrumbRoute
                                strict
                                title="Matrix Slotting"
                                path={`${path}slotting/`}
                                component={MatrixSlotting}
                              />
                              <CrumbRoute
                                strict
                                title="Bulk Slotting"
                                path={`${path}bulk-slotting/`}
                                component={BulkSlotting}
                              />
                              <Route component={NotFoundPage} />
                            </Switch>
                          )}
                        />
                        <Route component={NotFoundPage} />
                      </Switch>
                    )}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="ASNs"
              path="/asn"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={AsnList} />
                  <CrumbRoute exact companyRequired title="New" path={`${url}/new/`} component={CreateAsn} />
                  <CrumbRoute
                    strict
                    title="ASN Page"
                    path={`${url}/:id(\\d+)/`}
                    render={({ match: { path } }) => (
                      <Switch>
                        <Route exact strict path={`${path}`} render={(props) => <AsnPage {...props} />} />
                        <CrumbRoute exact strict title="Select Item" path={`${path}receive/`} component={SelectItem} />
                        <CrumbRoute
                          exact
                          strict
                          title="Confirm Variant Details"
                          path={`${path}item/:shipnotice_item_id(\\d+)/confirm-details/`}
                          component={ConfirmDetails}
                        />
                        <CrumbRoute
                          exact
                          strict
                          title="Receive Inventory"
                          path={`${path}item/:shipnotice_item_id(\\d+)/receive-inventory/`}
                          component={ReceiveInventory}
                        />
                        <CrumbRoute
                          exact
                          strict
                          title="Non-Compliance"
                          path={`${path}item/:shipnotice_item_id(\\d+)/non-compliance/`}
                          component={NonCompliance}
                        />
                      </Switch>
                    )}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="Returns"
              path="/return"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={ReturnList} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="Locations"
              path="/location"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={LocationList} />
                  <CrumbRoute exact title="Batch Edit Locations" path={`${url}/edit/`} component={LocationEdit} />
                  <CrumbRoute exact title="Location" path={`${url}/:id(\\d+)/`} component={LocationView} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="Products"
              path="/product"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={ProductList} />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Update Prices"
                    path={`${url}/upload-prices/`}
                    component={UploadPrices}
                  />
                  <CrumbRoute exact companyRequired title="Promo Rules" path={`${url}/rule/`} component={PromoRules} />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="New Product"
                    path={`${url}/new/`}
                    component={CreateProduct}
                  />
                  <CrumbRoute
                    exact
                    title="Product Variant"
                    path={`${url}/variant/:id(\\d+)/`}
                    component={ProductVariantPage}
                  />
                  <CrumbRoute exact title="Product" path={`${url}/:id(\\d+)/`} component={ProductPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <Route
              path="/postage"
              render={({ match: { url } }) => (
                <Switch>
                  <CrumbRoute exact title="Postage Reconcile" path={`${url}/reconcile/`} component={PostageReconcile} />
                  <CrumbRoute
                    exact
                    title="Rate Calculator"
                    path={`${url}/rate-calculator/`}
                    component={RateCalculator}
                  />
                  <CrumbRoute exact title="Postage Manifests" path={`${url}/manifests/`} component={PostageManifests} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="Projects"
              path="/project"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={ProjectList} />
                  <CrumbRoute exact title="Project" path={`${url}/:id(\\d+)/`} component={Project} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="EDI"
              path="/edi"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={EDITransactionList} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <Route
              path="/action"
              render={({ match: { url } }) => (
                <Switch>
                  <CrumbRoute exact title="Automations" companyRequired path={`${url}/`} component={Actions} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="Packaging"
              path="/package"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={PackageList} />
                  <CrumbRoute exact companyRequired title="New" path={`${url}/new/`} component={CreatePackaging} />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Packaging Rules"
                    path={`${url}/rule/`}
                    component={PackagingRules}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute
              title="Inventory"
              path="/inventory"
              render={({ match: { url } }) => (
                <Switch>
                  <Route exact path={`${url}/`} component={InventoryList} />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Stock Alerts"
                    path={`${url}/stock-alerts`}
                    component={StockAlertsPage}
                  />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Run Down Rate"
                    path={`${url}/run-down-rate`}
                    component={RunDownRatePage}
                  />
                  <CrumbRoute exact title="Move Inventory" path={`${url}/move/`} component={MoveInventoryPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <Route
              path="/billing"
              render={({ match: { url } }) => (
                <Switch>
                  <CrumbRoute exact companyRequired path={`${url}/company/`} component={BillingPage} />
                  <CrumbRoute
                    exact
                    title="Sales Commissions"
                    path={`${url}/commissions/`}
                    component={CommissionsReport}
                  />
                  <CrumbRoute exact title="Pricing Overview" path={`${url}/pricing/`} component={PricingTool} />
                  <CrumbRoute exact title="Pricing Tier" path={`${url}/pricing-tier/`} component={PricingTier} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <Route
              path="/company"
              render={({ match: { url } }) => (
                <Switch>
                  <Route
                    exact
                    path={`${url}/dashboard/`}
                    render={(props) =>
                      userIsWarehouse && !actAs ? (
                        <Redirect to="/warehouse/dashboard/" />
                      ) : (
                        <MerchantDashboard {...props} />
                      )
                    }
                  />
                  <CrumbRoute exact companyRequired title="Users" path={`${url}/users/`} component={CompanyUsers} />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Documents &amp; Reports"
                    path={`${url}/reports/`}
                    component={Reports}
                  />
                  <CrumbRoute
                    companyRequired
                    title="Webhooks"
                    path={`${url}/webhooks`}
                    render={({ match: { url } }) => (
                      <Switch>
                        <Route exact path={`${url}`} component={ListWebhookPage} />
                        <CrumbRoute
                          exact
                          path={`${url}/:id(\\d+)/events`}
                          title={'Webhook Events'}
                          component={WebhookEventsPage}
                        />
                        <Route component={NotFoundPage} />
                      </Switch>
                    )}
                  />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Packing Slip"
                    path={`${url}/packing-slip/`}
                    component={PackingSlip}
                  />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Shipping Methods"
                    path={`${url}/shipping-methods/`}
                    component={ShippingMethods}
                  />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Email Notifications"
                    path={`${url}/email-notifications/`}
                    component={EmailNotificationList}
                  />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Company Settings"
                    path={`${url}/settings/`}
                    component={CompanySettings}
                  />
                  <CrumbRoute exact title="Profile" path={`${url}/profile/`} component={Profile} />
                  <Redirect from={`${url}/billing/`} to="/billing/company/" />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <Route
              path="/integration"
              render={({ match: { url } }) => (
                <Switch>
                  <Route path={`${url}/shopify/`}>
                    <Redirect to={`${url}/shop/`} />
                  </Route>
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Loop Integration"
                    path={`${url}/loop/`}
                    component={LoopIntegrationPage}
                  />
                  <CrumbRoute
                    exact
                    title="Quickbooks Integration"
                    path={`${url}/quickbooks/`}
                    component={QuickbooksIntegrationPage}
                  />
                  <CrumbRoute
                    exact
                    companyRequired
                    title="Shopify Integration"
                    path={`${url}/shop/`}
                    component={ShopifyIntegrationPage}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            />
            <CrumbRoute exact title="Notifications" path={`/notification/`} component={NotificationPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Chrome>
      ) : (
        /* 
          ** Non Authorized Pages (User is not signed in) **
          NOTE: Some of these pages will also be available to signed in users above with the chrome UI attached.
        */
        <PostPage>
          <Background>
            <Border>
              <Switch>
                <Route path="/billing/pricing/" component={PricingTool} />
                <Redirect
                  to={{
                    pathname: '/login',
                    search: `?${searchParams}`,
                  }}
                />
              </Switch>
            </Border>
          </Background>
        </PostPage>
      )}
    </Switch>
  )
}

export const App = () => {
  return (
    <StateProvider>
      <ShippingTreeTheme>
        <ShippingTreeToastContainer />
        <Router>
          <MainRoutes />
        </Router>
      </ShippingTreeTheme>
    </StateProvider>
  )
}

export default App
